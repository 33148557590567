$(window).ready(function () {
    //Landing Page Shops
	if( $('.steps-list').length ) {
		$('.steps-list .step-item a').on('click', function(e) {
			e.preventDefault();
			$('html, body').animate({
				scrollTop: $($.attr(this, 'href')).offset().top - $('.fixed-header-wrapper').outerHeight()
			}, 500);
		});
    }
    
    // if( $(window).width() < 767 ) {
    //     $('#step-1').children('.container').hide().removeClass('hidden-xs').slideDown();
    //     $('#step-1').children('.mobile-timeline-navigation').addClass('active');
    //     $('#step-1').addClass('active-section');
    // }

    //Fallback for object-fit using Modernizr
    if ( ! Modernizr.objectfit ) {
        $('.timeline-step .image-wrapper').each(function () {
          var $container = $(this),
              imgUrl = $container.find('img').prop('src');
          if (imgUrl) {
            $container
              .css('backgroundImage', 'url(' + imgUrl + ')')
              .addClass('compat-object-fit');
          }  
        });
    }

    if( $(window).width() < 767 ) {
        if ( ! Modernizr.csspositionsticky ) {
            $('.mobile-timeline-navigation').stickybits({ 
                stickyBitStickyOffset: 54,
                useStickyClasses: true
            });
        }
    }
});

// Mobile Timeline Steps Animation
// Disabled due to bad UX
// var lastScrollTop = 0;
// // $(window).on('touchmove', throttle(function() {
// $(window).on('touchmove', function() {
//     if( $(window).width() > 767 ) 
//         return;

//     var st = $(this).scrollTop();
//     if (st > lastScrollTop) {
//         // downscroll code
//         var sectionInView,
//             sectionCurrent,
//             sectionPrev;
//         $('.timeline-step').each(function(e) {
//             if( $(this).isInViewport() ) {
//                 sectionInView = this;
//                 sectionPrev = $(this).prev();
//             }
//             if( $(this).isHalfVisibleViewport() ) {
//                 sectionCurrent = this;
//                 return false;
//             }

//             // if( $(sectionPrev).hasClass('active-section') && !$(this).is('#step-7') ) {
//             //     //Close previous sections after scrolling
//             //     $(sectionPrev).removeClass('active-section');
//             //     $(sectionPrev).children('.container').addClass('hidden-xs');
//             //     $(sectionPrev).children('.mobile-timeline-navigation').removeClass('active');
//             // }
//         });

//         if(sectionCurrent && sectionInView && !$(sectionCurrent).hasClass('active-section') && ( $(sectionPrev).hasClass('active-section') || $(sectionCurrent).is('#step-1') )) {
//             $(sectionCurrent).addClass('active-section');
//             $(sectionCurrent).children('.mobile-timeline-navigation').addClass('active');
//             $(sectionCurrent).children('.container').hide().removeClass('hidden-xs').slideDown();
            
//             $('html, body').animate({
//                 scrollTop: $(sectionCurrent).offset().top - $('.mobile-header').outerHeight()
//             }, 500, function() {
//                 // $(sectionCurrent).addClass('active-section');
//                 // $(sectionCurrent).children('.mobile-timeline-navigation').addClass('active');
//             });

//             // if( $(sectionPrev).hasClass('active-section') ) {
//             //     $(sectionPrev).removeClass('active-section');
//             //     $(sectionPrev).children('.container').slideUp().addClass('hidden-xs');
//             //     $(sectionPrev).children('.mobile-timeline-navigation').removeClass('active');
//             // }
//             return false;
//         }
     
//         // if(!sectionInView && !sectionCurrent && !sectionPrev) {
//         //     //out of last section
//         //     $('.timeline-step').each(function() {
//         //         if($(this).hasClass('active-section')) {
//         //             $(this).removeClass('active-section');
//         //             $(this).children('.container').addClass('hidden-xs');
//         //             $(this).children('.mobile-timeline-navigation').removeClass('active');
//         //         }
//         //     });
//         // }
//     } else {
//         // upscroll code
//     }
//     lastScrollTop = st;
// // }, 100));
// });

// Simple Working Solution
// var lastScrollTop = 0;
// 	$(window).on('resize scroll', function() {
// 		if( $(window).width() > 767 ) 
// 			return;
// 		var st = $(this).scrollTop();
// 		if (st > lastScrollTop){
// 			// downscroll code
// 			$('.mobile-timeline-navigation').each(function(e) {
// 				if($(this).isInViewport()) {
// 					if (!$(this).next('.timeline-step').is(':visible')) {
// 						$(this).addClass('active');
// 						$(this).next('.timeline-step').hide().removeClass('hidden-xs').slideDown();
// 						return false;
// 					}
// 				}
// 			});
// 		} else {
// 			// upscroll code
// 		}
// 		lastScrollTop = st;
// });

//Check if Element is in ViewPort
// $.fn.isHalfVisibleViewport = function() {
// 	var elementTop = $(this).offset().top;
// 	var elementBottom = elementTop + $(this).outerHeight();
// 	var viewportTop = $(window).scrollTop() + $('.mobile-header').outerHeight();
// 	var viewportBottom = viewportTop + $(window).height();
//     // return ((elementTop + ((elementBottom - elementTop)/2)) >= viewportTop && ((elementTop + ((elementBottom - elementTop)/2)) <= viewportBottom));
//     return elementBottom > viewportTop && elementTop < viewportBottom;
// };

$.fn.isHalfVisibleViewport = function() {
	var elementTop = $(this).offset().top;
	var elementBottom = elementTop + $(this).outerHeight();
	var viewportTop = $(window).scrollTop();
	var viewportBottom = viewportTop + $(window).height();
    return ((elementTop + ((elementBottom - elementTop)/2)) >= viewportTop && ((elementTop + ((elementBottom - elementTop)/2)) <= viewportBottom));
};

$.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();
    var viewportTop = $(window).scrollTop() + $('.mobile-header').outerHeight();
    var viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
};

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

function throttle(fn, wait) {
    var time = Date.now();
    return function() {
      if ((time + wait - Date.now()) < 0) {
        fn();
        time = Date.now();
      }
    }
};