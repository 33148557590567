$(window).resize(function () {
	offsetLangSwitcher();
});

$(window).ready(function () {
	offsetLangSwitcher();

	// Buttons click controller
	$('button, a').click(function (e) {
		var action = $(this).data('action');

		switch (action) {
			case 'close-notification':
	        	$(this).parents('.hosting-notification').addClass('invisible');
	        	offsetLangSwitcher();
	        break;
	        case 'show-navigation':
	        	$(this).parents('.mobile-header').toggleClass('menu-active');
	        	var icon = $(this).find('i');
	        	icon.toggleClass('fa-bars');
	        	icon.toggleClass('fa-times');
	        	
	        	$('.navbar-mobile').toggleClass('unfolded');
	        	$('.navbar-mobile').toggleClass('hidden');
	        	$('.page-overlay').toggleClass('hidden');
	        break;
	        case 'show-lang-menu':
	        	$(this).parent().toggleClass('active');
	        	$('.lang-switcher-menu').toggleClass('hidden');
	        break;
	        case 'change-lang':
	        	var changedLang = this.firstElementChild.innerHTML;
	        	$('.lang-item-active span').text(changedLang);
	        	console.log('Language changed to ' + changedLang);

	        	$('.lang-switcher-menu').toggleClass('hidden');
	        	$('.active-language').toggleClass('active');
	        break;

	         case 'show-lang-menu-mobile':
	        	$('.lang-switcher-menu').toggleClass('hidden');
	        	$('.mobile-navigation-toggle').toggleClass('lang-switcher-active');
	        break;
	        
	        case 'change-lang-mobile':
	        	var changedLang = this.firstElementChild.innerHTML;
	        	$('.lang-item-active span').text(changedLang);
	        	console.log('Language changed to ' + changedLang);

	        	$('.lang-switcher-menu').toggleClass('hidden');
	        	$('.mobile-navigation-toggle').toggleClass('lang-switcher-active');
	        break;

	        case 'show-content':
	        	$(this).siblings('.more-content').removeClass('hidden-xs');
	        	$(this).addClass('hidden-xs');
	        break;

	        case 'show-content-lg':
	        	e.preventDefault();
	        	$(this).siblings('.more-content').removeClass('hidden');
	        	$(this).addClass('hidden');
			break;
			
			case 'read-more-toggle':
				e.preventDefault();
				var readMoreContent = $(this).siblings('p').find('span');
				if($(window).width() < 768 && $(this).is('.visible-xs-inline-block')) {
					$(this).siblings('div.hidden-xs').hide().removeClass('hidden-xs').slideToggle('medium', function() {
						if ($(this).is(':visible'))
							$(this).css('display','inline-block');
					});
					$(this).addClass('hidden-xs');
				} else {
					$(this).addClass('fade-out');
					readMoreContent.slideToggle('medium', function() {
						if ($(this).is(':visible'))
							$(this).css('display','inline-block');
					});
				}
				console.log('clicked read more');
			break;

			case 'toggle-mobile-timeline':
				e.preventDefault();
				// $('.timeline-step').each(function() {
				// 	if( $(this).hasClass('active-section') ) {
				// 		$(this).removeClass('active-section');
				// 		$(this).children('.mobile-timeline-navigation').removeClass('active');
				// 		$(this).children('.container').addClass('hidden-xs');
				// 		// $(this).children('.container').slideUp(500, function(){
				// 		// });
				// 	}
				// })
				if( !$(this).parents('.timeline-step').hasClass('active-section') ) {
					$(this).parents('.timeline-step').addClass('active-section');
					$(this).parents('.timeline-step').children('.mobile-timeline-navigation').addClass('active');
					$(this).parents('.timeline-step').children('.container').hide().removeClass('hidden-xs').slideDown();
					$('html, body').animate({
						scrollTop: $(this).parents('.timeline-step').offset().top - $('.mobile-header').outerHeight()
					}, 500);
				} else {
					$(this).parents('.timeline-step').removeClass('active-section');
					$(this).parents('.timeline-step').children('.mobile-timeline-navigation').removeClass('active');
					$(this).parents('.timeline-step').children('.container').slideUp(500, function(){
						$(this).parents('.timeline-step').children('.container').addClass('hidden-xs');
					});
				}
			break;
			
	        default:
	        /* Add action here... */
	        break;
		}
	});

	$('.portfolio-carousel').slick({
		dots: true,
		arrows: false
	});


	var teamGalleryContainer = $('.team-gallery > .container > .row');
	// Team gallery slick slider only on phone views
	//TODO: Find a better way to merge these two
	if($(window).width() < 768) {
		teamGalleryContainer.slick({
			dots: true,
			arrows: false,
			slide: '.team-member'
		});
	} else {
        if(teamGalleryContainer.hasClass('slick-initialized'))
	  			teamGalleryContainer.unslick();
    }
    
	$(window).resize(function(){
    	if($(window).width() < 768) {
			teamGalleryContainer.slick({
				dots: true,
				arrows: false,
				slide: '.team-member'
			});
    	} else {
        	if(teamGalleryContainer.hasClass('slick-initialized'))
	  			teamGalleryContainer.unslick();
    	}
    });

	// Testimonials gallery slick slider only on phone views
    var testimonialsGalleryContainer = $('.testimonials-gallery > .container > .row');
	if($(window).width() < 768) {
		testimonialsGalleryContainer.slick({
			dots: true,
			arrows: false,
			slide: '.testimonial-item'
		});
	} else {
        if(testimonialsGalleryContainer.hasClass('slick-initialized'))
	  			testimonialsGalleryContainer.unslick();
    }
    
	$(window).resize(function(){
    	if($(window).width() < 768) {
			testimonialsGalleryContainer.slick({
				dots: true,
				arrows: false,
				slide: '.testimonial-item'
			});
    	} else {
        	if(testimonialsGalleryContainer.hasClass('slick-initialized'))
	  			testimonialsGalleryContainer.unslick();
    	}
    });    

	// Dropdown menu
	if($(window).width() < 768) {
		$('.dropdown-toggle').attr('data-toggle', 'dropdown');
	}

	$('.navbar-main li.dropdown').hover( function() {
		if($(window).width() < 768) 
			return;
		//MouseEnter
		$(this).addClass('open');
		$(this).find('ul.dropdown-menu').show();
	}, function() {	
		if($(window).width() < 768) 
			return;
		//MouseLeave
		$(this).removeClass('open');
		$(this).find('ul.dropdown-menu').hide();
	});

	//Back to top
	if ($('#back-to-top').length) {
		var scrollTrigger = 100, // px
		backToTop = function () {
		  var scrollTop = $(window).scrollTop();
		  if (scrollTop > scrollTrigger) {
		    $('#back-to-top').addClass('show');
		  } else {
		    $('#back-to-top').removeClass('show');
		  }
		};
		backToTop();
		$(window).on('scroll', function () {
		  backToTop();
		});
		$('#back-to-top').on('click', function (e) {
		  e.preventDefault();
		  $('html,body').animate({
		    scrollTop: 0
		  }, 700);
		});
	}

	// Sticky Header
	if ( $('.fixed-header-wrapper').length ) {
		var headerScrollOffset = $('.fixed-header-wrapper').offset().top;
	}
	$(window).scroll(function(e) {
		var scrollTop = $(window).scrollTop();
		var innerHeaderHeight = $('.navbar-block').outerHeight(true) + 'px';

		if( scrollTop >= headerScrollOffset) {
			$('.forcez-header').addClass('fixed');

			if( $('.forcez-header').hasClass('has-featured-image') == false ){
				$('.fixed-header-placeholder').css('height', innerHeaderHeight);
			}
		} else {
			$('.forcez-header').removeClass('fixed');

			if( $('.forcez-header').hasClass('has-featured-image') == false ){
				$('.fixed-header-placeholder').css('height', 0);
			}
			
		}
	});

	// Sticky Header Mobile
	if( $('.mobile-header').length ) {
		var headerScrollOffsetMobile = $('.mobile-header').offset().top;
	}

	$(window).scroll(function(e) {
		if( $(window).width() > 767 ) 
			return;

		var scrollTop = $(window).scrollTop();
		var innerHeaderHeightMobile = $('.mobile-header').outerHeight(true) + 'px';

		if( scrollTop >= headerScrollOffsetMobile) {
			$('.forcez-header').addClass('fixed');
			$('.fixed-mobile-header-placeholder').css('height', innerHeaderHeightMobile);
		} else {
			$('.forcez-header').removeClass('fixed');
			$('.fixed-mobile-header-placeholder').css('height', 0);
		}
	});
});



//Position Lang switcher
function offsetLangSwitcher() {
	if( !$('body').is('.home') ) {
		return; 
	}

	var offsetTop = $('.hosting-notification').height() ;

	if( $('.hosting-notification').is('.invisible') ) {
		offsetTop = 0;
	}
	
	$('.lang-switcher-block').css('top', offsetTop+'px');
}

// gMap for contact page
function initMap() {
  var fzCoord = {lat: 51.593884, lng: 4.800880};
  var map = new google.maps.Map(document.getElementById('map'), {
    zoom: 18,
    center: fzCoord
  });
  var marker = new google.maps.Marker({
    position: fzCoord,
    map: map
  });
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};